/* eslint-disable no-unused-vars */
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  CircularProgress,
  IconButton,
  Pagination,
  PaginationItem,
  Paper,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Stack from "@mui/material/Stack";
import WidgetsIcon from "@mui/icons-material/Widgets";
import checkcircle from "./image/Check Circle.png";
import { useNavigate, useParams } from "react-router-dom";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../../service/firebase_init";
import QuizDailogPageTwo from "./QuizDailogPageTwo";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

const QuizPagetwo = () => {

  const rnavigator = useNavigate();

  const [note, setNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subNote, setSubNote] = useState([]);
  const [page, setPage] = useState(1);
  const [showHint, setShowHint] = useState(false);

  const [open, setOpen] = useState(false);

  const onClose = (v) => {
    if (v !== null) {
      setPage(v + +1);
    }
    setOpen(false);
  };

  const handleOnChange = async (e, v) => {
    setPage(v);
  };

  const addAnswer = async (v, i) => {
    const c = subNote;
    c[page - 1].userAns = v;
    setSubNote([...c]);
    const d = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/quizStatus/" + qId
    );
    const g = await getDoc(d);
    const ii = subNote[page - 1].id;

    const correctAnsL = [];

    for (const [index, checkA] of c.entries()) {
      if (c[index].userAns !== null) {
        const dataA = doc(
          db,
          "notes/" + id + "/subChapters/" + qId + "/quiz/" + checkA.id
        );
        const dA = await getDoc(dataA);

        if (dA.data() && dA.data().correctAns === c[index].userAns) {
          correctAnsL.push(c[index].userAns);
        }
      }
    }

    const p = 100 / subNote.length;
    const score = correctAnsL.length === 0 ? 0 : correctAnsL.length * p;

    let ans = {};
    if (g.data()) {
      if (Object.keys(g?.data()?.answersList ?? {})?.length) {
        ans = {
          ...g.data().answersList,
        };
      }
    }
    ans[ii] = v;
    await setDoc(
      d,
      {
        ...(!g.data() && { createdAt: Timestamp.now() }),
        answersList: ans,
        correntIndex: page,
        time: 0,
        compliteAt: Timestamp.now(),
        notesId: id,
        score: score,
        subjectId: qId,
        totalQuiz: note.quizCount,
        userId: localStorage.getItem("uId"),
      },
      { merge: true }
    );

    if (page === c.length) {
    }
  };

  const { id, qId } = useParams();

  const getData = async () => {
    setIsLoading(true);
    const data = query(doc(db, "notes/" + id + "/subChapters/", qId));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }
    setIsLoading(false);
  };

  const getSubNote = async () => {
    const data = collection(
      db,
      "notes/" + id + "/subChapters/" + qId + "/quiz"
    );
    const d = await getDocs(data);
    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        t.push(i.data());
      }

      getQuizStatus(t);
    }
  };

  const getQuizStatus = async (t) => {
    const d = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/quizStatus/" + qId
    );

    const dd = await getDoc(d);
    if (dd.data()) {
      if (dd.data().answersList) {
        const keys = Object.keys(dd.data().answersList);
      }

    }
    setSubNote(t);
  };

  useEffect(() => {
    getData();
    getSubNote();
  }, []);

  return isLoading ? (
    <Box display="flex" justifyContent="center" height="50vh">
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        marginBlock: "2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: { xs: "80%", md: "70%", lg: "55%" },
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#F9F9F9",
          px: { xs: "1rem", md: "2rem" },
          py: "0.8rem",
          borderRadius: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton onClick={() => rnavigator(-1)}>
            <ArrowBackIosIcon />           
          </IconButton>
          <Typography variant="body1">Quiz {note.name}</Typography>
          <Typography
            variant="body2"
            sx={{
              ml: "0.5rem",
              color: "#565656",
            }}
          >
            {note.quizCount} Question
          </Typography>
        </Box>
        <Box
          sx={{
            cursor: "pointer",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <WidgetsIcon />
        </Box>
        <Button onClick={() => rnavigator("result")} variant="outlined">
          Submit
        </Button>
      </Box>

      {subNote.length === 0 ? null : (
        <Box
          sx={{ }}
        >
          <Paper
            elevation={4}
            sx={{
              borderRadius: "18px",
              m: 3,
            }}
          >
            <Box
              sx={{
                padding: "1.5rem",
                m: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "550",
                    }}
                  >
                    {page}.{subNote[page - 1].question}
                  </Typography>
                </Box>
                <Box
                  onClick={() => setShowHint((p) => !p)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#c0c0c0",
                    padding: "10px",
                    borderRadius: "25px",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      pl: 0.5,
                      fontWeight: "bold",
                      color: "#000",
                    }}
                  >
                    {showHint ? "Hide" : "Hint"}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {showHint && (
                  <Box>
                    
                    <Box
                      sx={{
                        display: "inline-block",
                        padding: "5px",
                        borderRadius: 25,
                        marginTop: 3,
                      }}
                    >
                    </Box>
                    <Typography>
                      <span style={{ fontWeight: "bold" }}>Description :</span>{" "}
                      {subNote[page - 1].description}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  pt: 0.8,
                  pl: 1,
                }}
              >
                <img
                  src={subNote[page - 1].poster}
                  alt=""
                  width="50%"
                  style={{ margin: "0", padding: "0" }}
                  height="auto"
                />
              </Box>
              <Box
                sx={{
                  borderRadius: "27px",
                  width: "80%",
                }}
              >
                <Box>
                  {subNote[page - 1].options.map((v, i) => {
                    return (
                      <QuizeBoxView
                        key={i}
                        a={subNote[page - 1].correctAns}
                        i={i}
                        v={v}
                        ua={subNote[page - 1].userAns}
                        addAnswer={addAnswer}
                      />
                    );
                  })}
                  
                </Box>
              </Box>
            </Box>
          </Paper>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CustomIcons
              page={page}
              count={note.quizCount}
              handleOnChange={handleOnChange}
            />
          </Box>
        </Box>
      )}

      {open && (
        <QuizDailogPageTwo id={id} onClose={onClose} open={open} qId={qId} />
      )}
    </Box>
  );
};

export default QuizPagetwo;

function QuizeBoxView({ v, i, a, addAnswer, ua }) {
  return (
    <Box
      sx={{
        cursor: "pointer",

        ...(ua !== null &&
          ua === v && {
            backgroundColor: v === a ? "#15423F" : "#15423F",
            borderRadius: "27px",
          }),
      }}
      
      onClick={() => {
        addAnswer(v, i);        
      }}
    >
      <QuizViwe
        value={`${getAlpha(i)} ${v}`}
        image={checkcircle}
        check={v === a}
        ua={ua}
        v={v}
      />
    </Box>
  );
}

const getAlpha = (i) => {
  let v = "A";
  if (i === 0) {
    v = "A";
  } else if (i === 1) {
    v = "B";
  } else if (i === 2) {
    v = "C";
  } else if (i === 3) {
    v = "D";
  } else {
    v = "A";
  }

  return v;
};

function CustomIcons({ page, count, handleOnChange }) {
  return (
    <Box
      sx={{
        mt: 2,
      }}
    >
      <Stack spacing={1}>
      <Pagination
        count={count}
        page={page}
        onChange={handleOnChange}
        siblingCount={1}
        boundaryCount={2}
        size="large"
        variant="text"
        shape="rounded"
        renderItem={(item) => (
          <PaginationItem
            {...item}
            components={{
              previous: () => <span style={{ backgroundColor: '#1e90ff ', padding: '7px', borderRadius: '5px', color: 'white' }}> <FontAwesomeIcon icon={faChevronLeft} /> Previous</span>,
              next: () => <span style={{ backgroundColor: '#1e90ff ', padding: '7px', borderRadius: '5px', color: 'white' }}>Next <FontAwesomeIcon icon={faChevronRight} /></span>,
            }}
          />
        )}
      />
    </Stack>
    </Box>
  );
}

function QuizViwe({ value, subtitle, image, check, ua, v }) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          backgroundColor: "rgba(21, 66, 63, 0.02);",
          borderRadius: "27px",
          border: "1px solid rgba(19, 80, 80, 0.3)",
          marginBlock: "0.5rem",
          paddingBlock: "0.5rem",
          paddingInline: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              textTransform: "none",
              color: "#15423F",
              ...(ua !== null && ua === v && { color: "#fff" }),
            }}
          >
            {value}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="body1"
            sx={{
              color: "#135050",
              textTransform: "none",
              fontWeight: "300",
            }}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
