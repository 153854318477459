/* eslint-disable no-unused-vars */
import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import doctor from "./image/Doctor.png";
import Typography from "@mui/material/Typography";
import { CircularProgress, Paper } from "@mui/material";
import QuizPagethree from "./QuizPagethree";
import { useState } from "react";
import { collection, doc, getDoc, getDocs, query } from "firebase/firestore";
import { db } from "../../../service/firebase_init";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

let attempQ = 0;
let notAttemp = 0;
let correctAns = 0;
let inCorrectAns = 0;
let totalQ = 0;
let scorQ = 0;

const QuizPagethreeque = () => {
  const { id, qId } = useParams();
  const [note, setNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [subNote, setSubNote] = useState([]);
  const [selected, setSelected] = useState(0);
  const [userQ, setUserQ] = useState({});

  const getData = async () => {
    const data = query(doc(db, "notes/" + id + "/subChapters/", qId));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }
  };

  const getSubNote = async () => {
    const data = collection(
      db,
      "notes/" + id + "/subChapters/" + qId + "/quiz"
    );
    const d = await getDocs(data);

    const dU = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/quizStatus/" + qId
    );

    const gU = await getDoc(dU);
    setUserQ(gU.data());

    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        t.push(i.data());
      }

      getQuizStatus(t);
    }
  };

  const getQuizStatus = async (t) => {
    attempQ = 0;
    notAttemp = t.length;
    correctAns = 0;
    inCorrectAns = 0;
    totalQ = t.length;

    const d = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/quizStatus/" + qId
    );

    const dd = await getDoc(d);
    if (dd.data()) {
      scorQ = dd.data().score;
      const keys = Object.keys(dd.data().answersList);

      if (keys.length) {
        attempQ = keys.length;
        notAttemp = t.length - attempQ;
        for (const [index, val] of t.entries()) {
          if (keys.includes(val.id)) {            
            t[index].userAns = dd.data().answersList[val.id];
          }
        }

        for (const [index, checkA] of t.entries()) {
          if (t[index].userAns !== null) {
            const dataA = doc(
              db,
              "notes/" + id + "/subChapters/" + qId + "/quiz/" + checkA.id
            );
            const dA = await getDoc(dataA);

            if (dA.data() && dA.data().correctAns === t[index].userAns) {
              t[index].ansStatus = 1;
              correctAns += 1;
            } else {
              t[index].ansStatus = -1;
              inCorrectAns += 1;
            }
          } else {
            t[index].ansStatus = 0;
          }
        }
        setSubNote(t);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
    getSubNote();
  }, []);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <CircularProgress />
    </Box>
  ) : (
    <>
      <QuizPagethree
        com={userQ.compliteAt}
        start={userQ.createdAt}
        scorQ={scorQ}
        totalQ={totalQ}
        attempQ={attempQ}
        notAttemp={notAttemp}
        correctAns={correctAns}
        inCorrectAns={inCorrectAns}
      />
      <Box
        sx={{
          display: "flex",

          justifyContent: "center",
          flexDirection: { xs: "column", md: "row" },
          marginBottom: "3rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box>
            <NestedList subNote={subNote} changeIndex= {(index)=>setSelected(index)} />
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "500px" },
          }}
        >
          <Paper
            elevation={0}
            sx={{
              borderRadius: "18px",
            }}
          >
            <Box
              sx={{
                padding: "1.5rem",
                mt: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "center", md: "normal" },
              }}
            >
              <Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "550",
                  }}
                >
                  {selected + 1}.{subNote[selected].question}
                </Typography>
              </Box>

              <Box
                sx={{
                  pt: 0.8,
                  pl: { xs: "0", md: "1rem" },
                }}
              >
                <img
                  src={subNote[selected].poster}
                  alt=""
                  style={{ margin: "0", padding: "0" }}
                  height="auto"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: { xs: "center", md: "normal" },
                }}
              >
                <Box
                  sx={{
                    width: { xs: "100%", sm: "60%", md: "70%" },
                  }}
                >
                  {subNote[selected].options.map((v) => {
                    var keyVal = v.trim();                    
                    return (
                      <Box
                      key={keyVal}
                        sx={{
                          backgroundColor:
                          keyVal === subNote[selected].correctAns.trim() ? "#7fff00":(subNote[selected].correctAns.trim() !== subNote[selected].userAns.trim() ? (keyVal === subNote[selected].userAns.trim() ? "#ff9999" : "") : ""),
                          borderRadius: "27px",
                        }}
                      >
                        <QuizViwe subtitle={keyVal} key={keyVal} />
                      </Box>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    paddingTop: "0.5rem",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: { xs: "center", md: "normal" },
                  }}
                >
                  {subNote[selected].description !== null ? (
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "500",
                      }}
                    >
                      Explanation
                    </Typography>
                  ) : null}

                  <Typography
                    variant="body2"
                    sx={{
                      paddingTop: "0.5rem",
                    }}
                  >
                    {subNote[selected].description}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default QuizPagethreeque;
function NestedList({ subNote,changeIndex }) {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      sx={{
        width: { xs: "250px", sm: "350px", md: "350px" },
        maxWidth: 360,
        borderRadius: "13px",
        backgroundColor: "#F4F4F4",
        padding: "1rem",
      }}
    >
      <ListItemButton
        onClick={handleClick}
        sx={{
          backgroundColor: "rgba(205, 205, 205, 0.2);",
          borderRadius: "13px",
        }}
      >
        <ListItemText primary="All Question" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{
            marginTop: "1rem",
          }}
        >
          {subNote.map((val, index) => {
            return (
              <ListItemButton
              onClick={()=>changeIndex(index)}
                key={index}
                sx={{
                  backgroundColor:
                    val.ansStatus === 1
                      ? "rgba(28, 187, 44, 0.2);"
                      : val.ansStatus === -1
                      ? "rgba(187, 28, 86, 0.2);"
                      : "rgba(123, 130, 124, 0.2);",
                  borderRadius: "13px",
                  marginBottom: "1rem",
                }}
              >
                <ListItemText primary={`Question ${index + 1}`} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </List>
  );
}
function QuizViwe({ value, subtitle }) {
  return (
    <Box
      sx={{
        backgroundColor: "rgba(21, 66, 63, 0.02);",
        borderRadius: "27px",
        border: "1px solid rgba(19, 80, 80, 0.3)",
        marginBlock: "0.5rem",
        paddingBlock: "0.5rem",
        paddingInline: "1rem",
        color: "#135050",
        textTransform: "none",
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{
            textTransform: "none",
            fontWeight: "300",
          }}
        >
          {value}
        </Typography>
        
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: "300" }}>
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
