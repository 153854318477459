import { Box, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Rectangle6 from "./image/Rectangle 6.png";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Vector2 from "./image/Vector 2.png";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
 
} from "firebase/firestore";
import { db } from "../../service/firebase_init";
import { useNavigate } from "react-router-dom";
import { MoreVert } from "@mui/icons-material";
import EditPlannerDailog from "../Dailog/EditPlannerDailog";
import ConfirmationDailog from "../Dailog/ConfirmationDailog";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  width: "90%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D0D0D0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#135050" : "#D0D0D0    ",
  },
}));

moment.locale("en-GB");
const localizer = momentLocalizer(moment);
const Planner = () => {
  const [eventsData, setEventsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const events = [];

  const [openEP,setOpenEP] = useState(false);
  const [openCP,setOpenCP] = useState(false);
  const [selectedRow,setSelectedRow] = useState(null);

  const editHandleOpen = (v)=>{
    setSelectedRow(v);
    setOpenEP(true);
  }

  const editHandleOpenC = (v)=>{
    setSelectedRow(v);
    setOpenCP(true);
  }

  const editHandleClose = async (v,dateTime)=>{
    if(v){

      const d = doc(db,"users/"+localStorage.getItem("uId")+"/planner/"+selectedRow.chapterId);

    await  setDoc(d,{
        dueDate:Timestamp.fromDate(dateTime),
      },{merge:true})
     
      getData();
    }
    setSelectedRow(null);
    setOpenEP(false);
  }

  const editHandleCloseC = async (v)=>{
    if(v){

      const type = getCollectionName(selectedRow.type);
      const d = query(collection(db,"users/"+localStorage.getItem("uId")+"/"+`${type}`),where("notesId","==",selectedRow.chapterId));
      const dp = doc(db,"users/"+localStorage.getItem("uId")+"/planner/"+selectedRow.chapterId);
      const dd = await getDocs(d);
      const dpp = await getDoc(dp);

      await deleteDoc(dpp.ref);
      if(dd.docs.length){
        for(const i of dd.docs){
          await deleteDoc(i.ref);
        }
      }
     
      getData();
    }
    setSelectedRow(null);
    setOpenCP(false);
  }

  const getCollectionName = (v)=>{

    if(v === 0){
      return "notesStatus";
    }else if(v === 1){
      return "quizStatus";
    }else if(v === 2){
      return "cardStatus";
    }
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    const tempData = [];
    const data = collection(
      db,
      "users",
      localStorage.getItem("uId"),
      "planner"
    );

    const d = await getDocs(data);

    if (d.docs.length) {
      for (let [index, val] of (d.docs.length ? d.docs : []).entries()) {
        tempData.push(val.data());

        const dd = doc(db, "notes/" + val.data()["chapterId"]);
        const v = await getDoc(dd);

        if (v.exists()) {
          tempData[index].notes = v.data();
          const ii = {
            id: index,
            title: v.data()["name"],
            start: new Date(val.data()["createdAt"].toDate()),
            end: new Date(val.data()["dueDate"].toDate()),
            resourceId: val.id,
          };
          events.push(ii);

          let tb =
            val.data()["type"] === 1
              ? "noteStatus"
              : val.data()["type"] === 2
              ? "quizStatus"
              : "cardStatus";

          const cardCheck = query(
            collection(db, "users/" + localStorage.getItem("uId") + "/" + tb),
            where("notesId", "==", val.data()["chapterId"]),
            where("userId", "==", localStorage.getItem("uId"))
          );
          const gets = await getDocs(cardCheck);

          tempData[index].completed = gets.docs.length;

          if (val.data()["type"] === 1) {
            tempData[index].count = v.data()["notesCount"];
          } else if (val.data()["type"] === 2) {
            tempData[index].count = v.data()["quizCount"];
          } else {
            tempData[index].count = v.data()["flashCardCount"];
          }
        }
      }
    }
    setEventsData([...events]);
    setData([...tempData]);
    setIsLoading(false);
  };

  const handleSelect = ({ start, end }) => {    
    const title = window.prompt("New Event name");
    if (title)
      setEventsData([
        ...eventsData,
        {
          start,
          end,
          title,
        },
      ]);
  };

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="80vh"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "center",
        marginBlock: "2rem",
      }}
    >
      <Box
        sx={{
          marginRight: { xs: "0", md: "2rem" },
          display: "flex",
          justifyContent: "center",
          height: "550px",
          width: { xs: "100vw", md: "auto" },
        }}
      >
        <Calendar
          style={{ width: "800px" }}
          allDayAccessor
          views={["month"]}
          selectable
          localizer={localizer}
          defaultView="month"
          events={eventsData}
        />
      </Box>
      <Box
        sx={{
          height: "60vh",
          overflow: "scroll",
          backgroundColor: "#F6F6F6",
          borderRadius: "15px",
          marginLeft: { xs: "0", md: "2rem" },
          marginInline: { sm: "3rem", md: "0" },
          marginTop: { xs: "2rem", md: "0" },
        }}
      >
        {data.map((val, index) => {
          return <PlannerViwe editHandleOpen={editHandleOpen} editHandleOpenC={editHandleOpenC}  key={index} value={val} />;
        })}
      </Box>

        {openEP && <EditPlannerDailog handleClose={editHandleClose} open={openEP} row={selectedRow}/>}
        {openCP && <ConfirmationDailog handleOnClose={editHandleCloseC} open={openCP} title="Planner delete!" content="Are you sure, You want to delete planner?" />}

    </Box>
  );
};

export default Planner;

function PlannerViwe({ value,editHandleOpen,editHandleOpenC }) {
  const navigator = useNavigate();

  const getDiff = (d1) => {
    var a = moment(d1, "dd.mm.yyyy");
    var b = moment(new Date(), "dd.mm.yyyy");
    return a.diff(b, "days"); // 1
  };

  const [anchrEL, setAnchrEL] = useState(null);
  const open = Boolean(anchrEL);

  const handleOnClick = (e) => {
    setAnchrEL(e.currentTarget);
  };

  const handleOnClose = () => {
    setAnchrEL(null);
  };

  return (
    <Box>
      
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              borderRadius: "5px",
              marginRight: "1rem",
            }}
          >
            <img src={value.notes.poster} width="46px" height="64px" alt="" />
          </Box>

          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" color="initial">
                {value.notes.name}
              </Typography>
              <Box
                sx={{
                  height: "10px",
                  width: "10px",
                  background: `${
                    getDiff(value.dueDate.toDate()) >= 0 &&
                    getDiff(value.dueDate.toDate()) < 7
                      ? "red"
                      : "green"
                  }`,
                  borderRadius: "50%",
                  ml: 1,
                }}
              ></Box>
              
            </Box>
            <Typography variant="body2" color="initial">
              Due Date :{" "}
              {new Date(value.dueDate.toDate()).toLocaleString().split(",")[0]}
            </Typography>
            <BorderLinearProgress
              variant="determinate"
              value={
                value.completed === 0 ? 0 : (value.completed / value.count) * 100
              }
            />
          </Box>
        </Box>

       <Box
       display="flex"
       >
       <IconButton onClick={handleOnClick}>
          <MoreVert />
        </IconButton>

        <Menu
        
        open={open}
        anchorEl={anchrEL}
        onClose={handleOnClose}
        PaperProps={{
          style:{
            borderRadius:"8px",
            borderColor:"primary.main"
          }
        }}
        >
          <MenuItem onClick={()=>{
            setAnchrEL(false);
            editHandleOpen(value);
          }}> Edit </MenuItem>
          <MenuItem onClick={()=>{
             setAnchrEL(false);
             editHandleOpenC(value)
          }}> Delete </MenuItem>
          <MenuItem
         
          onClick={() => {
            setAnchrEL(false);
              if (value.type === 0) {
                navigator("/home/notes/" + value.chapterId);
              } else if (value.type === 1) {
                navigator("/home/quiz/" + value.chapterId);
              } else if (value.type === 2) {
                navigator("/home/flash-card/" + value.chapterId);
              }
            }}> View </MenuItem>
        </Menu>
        
       </Box>
      </Box>
    </Box>
  );
}
