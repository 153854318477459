import React, { useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  Drawer,
  Popover,
  CircularProgress,
} from "@mui/material";
import logo from "../../Components/Home/image/education.png";
import magnifer from "./image/magnifer.png";
import calendar from "./image/Calendar.png";
import rounded from "./image/Two Rounded.png";
import bell from "./image/bell.png";
import ellipse from "./image/ellipse 1.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import Login from "./image/Login2.png";
import Notification from "../Notification/Notification";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../service/firebase_init";
import { Logout } from "@mui/icons-material";
import { isUserPremium } from "../../App";
import axios from "axios";
import ConfirmationDailog from "../Dailog/ConfirmationDailog";
import { stripe_sk } from "../../variables";
import { APP_NAME } from "../../App.js";

const Navbar = () => {
  const navigator = useNavigate();
  return (
    <>
      <Box
        display="flex"
        sx={{
          backgroundColor: "#135050",
          height: { xs: "auto", md: "55px" },
          alignItems: "center",
          justifyContent: "space-between",
          color: "#fff",
          paddingInline: { xs: "0.5rem", sm: "1rem" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigator("/home", { replace: true });
          }}
        >
          <img
            src={logo}
            alt="logo"
            width="35px"
            height="35px"
            style={{ marginRight: "0.5rem" }}
          />
          <Typography variant="body1" sx={{}}>
            {APP_NAME}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
              },
            }}
          >
            <Box
              onClick={() => navigator("home/planner")}
              sx={{
                marginRight: { xs: "0", lg: "0.5rem" },
              }}
            >
              <PlannerView images={calendar} value="Planner" />
            </Box>

            <Box
              onClick={() => navigator("home/form-link")}
              sx={{
                marginLeft: { xs: "0", lg: "0.5rem" },
              }}
            >
              <PlannerView images={rounded} value="Forum" />
            </Box>
          </Box>
        </Box>
        <ProfileView />
      </Box>
    </>
  );
};

export default Navbar;

// -----Function one time create-----

function TextFielView() {
  return (
    <Box
      alignItems="center"
      position="relative"
      sx={{
        marginLeft: { xs: "0", sm: "1rem", md: "1.5rem" },
        display: { xs: "none", sm: "flex" },
      }}
    >
      <Box display="flex" alignItems="center">
        <img
          src={magnifer}
          alt=""
          width="30px"
          height="30px"
          style={{ position: "absolute" }}
        />
      </Box>
      <Box>
        <TextField
          placeholder="Search  Notes, Flash Cards, Video Course, Quiz, People ..."
          sx={{
            width: { xs: "none", sm: "16rem", md: "18rem", lg: "30rem" },
            input: {
              color: "#fff",
              margin: "20px",
              fontSize: "13px",
              border: "none",
              "&::placeholder": {
                color: "#fff",
                opacity: "0.5",
                fontSize: "13px",
              },
            },
          }}
          InputProps={{
            style: { height: "2.5rem" },
          }}
        />
      </Box>
      <Box>
        <Button
          sx={{
            backgroundColor: "#fff",
            color: "#000",
            height: "2.2rem",
            margin: "5px",
            textTransform: "none",
            fontSize: "12.5px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#12B1B1",
              color: "white",
            },
          }}
        >
          Search
        </Button>
      </Box>
    </Box>
  );
}

function ProfileView() {
  const [anchorEl, setAnchorEl] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [user, setUser] = useState(null);
  const getData = async () => {
    const d = doc(db, "users/" + localStorage.getItem("uId"));
    const u = await getDoc(d);
    if (u.data()) {
      setUser(u.data());
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [state, setState] = React.useState(false);
  const [openC, setOpenC] = React.useState(false);

  const handleOnClose = async (v) => {

    setOpenC(false);

    if (v) {
      setUnLoader(true);
      axios
        .delete(
          "https://api.stripe.com//v1/subscriptions/" + user?.subscriptionID,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: "Bearer "+stripe_sk,
            },
          }
        )
        .then(async (response) => {
          const data = doc(db, "users", localStorage.getItem("uId"));
          await setDoc(
            data,
            {
              subscriptionID: null,
              isSubscribe: false,
              subscribetiondate: null,
              subscriptionEndDate: null,
              paymentMode: null,
            },
            { merge: true }
          );
          setUnLoader(false);
          window.location.reload();
        })
        .catch(async (e) => {
          setUnLoader(false);
          console.log(e);
          alert("Oops! Something went wrong. Please try again.");
        });

    }
  };

  const toggleDrawer = () => {
    setState((p) => !p);
  };

  const navigator = useNavigate();
  const [unLoader, setUnLoader] = useState(false);

  return (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          cursor: "pointer",
          alignItems: "center",
          display: { xs: "none", md: "flex" },
        }}
      >
        <Box display="flex" alignItems="center">
          {unLoader ? (
            <CircularProgress sx={{ color: "white", mr: 1 }} size={15} />
          ) : (
            <Typography
              onClick={() => {
                if (user?.paymentMode === "v2" && isUserPremium) {
                  setOpenC(true);
                }
              }}
              sx={{
                mr: 1,
                cursor: "auto",
                color: "yellow",
                fontSize: "5px",
                cursor:
                  user?.paymentMode === "v2" && isUserPremium
                    ? "pointer"
                    : "default",
              }}
            >
              {user?.paymentMode === "v2" && isUserPremium
                ? "Unsubscribe"
                : isUserPremium
                  ? "Premium"
                  : ""}
            </Typography>
          )}

          <Box aria-describedby={id} display="flex" onClick={handleClick}>
            <Typography sx={{ mr: 0.5 }}>Notification</Typography>
            <img
              src={bell}
              alt=""
              width="27px"
              height="27px"
              style={{
                marginRight: "0.7rem",
              }}
            />
          </Box>

          <Popover
            sx={{
              display: { xs: "none", md: "flex" },
            }}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Notification />
          </Popover>
          <Box
            onClick={() => navigator("home/form")}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography sx={{ mr: 0.5 }}>Profile</Typography>
            <img
              src={user?.profilePhoto ?? ellipse}
              alt=""
              width="35px"
              height="35px"
              style={{ borderRadius: "50%" }}
            />
          </Box>
          <Box
            aria-describedby={id}
            display="flex"
            alignItems="center"
            sx={{ mx: 1 }}
            onClick={async () => {
              await auth.signOut();
            }}
          >
            <Typography sx={{ mr: 0.5 }}>Logout</Typography>
            <Logout sx={{ transform: "rotate(90deg)" }} fontSize="35px" />
          </Box>
        </Box>

      </Box>
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          alignItems: "center",
        }}
      >
       

        <IconButton
          sx={{ display: { xs: "flex", md: "none", color: "#fff" } }}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <Drawer
        anchor={"right"}
        open={state}
        onClose={() => toggleDrawer(true)}
        PaperProps={{
          sx: {
            backgroundColor: "#135050;",
          },
        }}
      >
        <Box
          palette={0}
          sx={{
            p: 5,
          }}
        >
          <Box
            palette={0}
            sx={
              {
              }
            }
          >
            <Box
              onClick={() => toggleDrawer()}
              style={{
                display: "flex",
                flexDirection: "column",
                color: "#000",
              }}
            >
              <Box onClick={() => navigator("home/planner")}>
                <PlannerView images={calendar} value="Planner" />
              </Box>
              <Box onClick={() => navigator("home/form-link")}>
                <PlannerView images={rounded} value="Forum" />
              </Box>              
              <Box onClick={() => navigator("home/form")}>
                <PlannerView images={ellipse} value="My Profile" />
              </Box>
              <Box onClick={async () => await auth.signOut()}>
                <PlannerView images={Login} value="Log Out" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Drawer>
      {openC && (
        <ConfirmationDailog
          open={openC}
          handleOnClose={handleOnClose}
          content="Are you sure you want to unsubscribe ?"
          title="Unsubscribe!"
        />
      )}
    </Box>
  );
}
function PlannerView({ images, value }) {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box>
        <img
          src={images}
          alt=""
          width="30px"
          style={{
            margin: "0.3rem",
          }}
        />
      </Box>
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "400",
            color: "white",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
