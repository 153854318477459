import { Box } from "@mui/material";
import React from "react";
import Notes from "../../Components/Notes/Notes";

function NotePage() {

  return (
    <Box>
      <Notes />
    </Box>
  );
}

export default NotePage;
