import React from "react";
import { Box, CircularProgress } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { Typography } from "@mui/material";
import plan from "./image/Plan.png";
import ArrowRight from "./image/Arrow-Right.png";
import { useEffect } from "react";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import { auth, db } from "../../../service/firebase_init";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PlannerDailog from "../../Dailog/PlannerDailog";
import { styled } from "@mui/material/styles";
import { dateFormatDDMMMYYYY } from "../../../service/date";
import { isUserPremium } from "../../../App";
import { Lock } from "@mui/icons-material";
import Plan from "../../../Pages/Plan";
import axios from "axios";
import qs from "qs";
import { stripe_sk, cancel_url_var, success_url_var } from "../../../variables";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  width: "90%",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#D0D0D0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#135050" : "#D0D0D0",
  },
}));

const Details = () => {
  const [note, setNote] = useState(null);
  const [subNote, setSubNote] = useState([]);
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [completed, setCompleted] = useState(-1);
  const [planner, setPlanner] = useState(null);

  const getPlannerData = async () => {
    const check = query(
      collection(db, "users/" + localStorage.getItem("uId") + "/planner"),
      where("chapterId", "==", id),
      where("userId", "==", localStorage.getItem("uId"))
    );
    const get = await getDocs(check);

    if (get.docs.length) {
      setPlanner(get.docs[0].data());
      const quizCheck = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/noteStatus"
        ),
        where("notesId", "==", id),
        where("userId", "==", localStorage.getItem("uId"))
      );
      const gets = await getDocs(quizCheck);
      setCompleted(gets.docs.length);
    }
  };

  const getData = async () => {
    setIsLoading(true);
    const data = query(doc(db, "notes", id));
    const d = await getDoc(data);
    if (d.data()) {
      setNote(d.data());
    }

    setIsLoading(false);
    getPlannerData();
  };

  const getSubNote = async () => {
    const data = query(
      collection(db, "notes/" + id + "/subChapters"),
      orderBy("index", "asc")
    );
    const d = await getDocs(data);
    if (d?.docs?.length) {
      const t = [];
      for (const i of d.docs) {
        t.push(i.data());
      }
      setSubNote(t);
    }
  };

  useEffect(() => {
    getData();
    getSubNote();
  }, []);

  const handleClose = async (v = 0, dateTime) => {    
    setOpen(false);
    if (v) {
      const data = doc(db, "users", localStorage.getItem("uId"), "planner", id);

      const check = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/planner"
        ),
        where("chapterId", "==", id),
        where("userId", "==", localStorage.getItem("uId"))
      );
      const quizCheck = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/noteStatus"
        ),
        where("notesId", "==", id),
        where("userId", "==", localStorage.getItem("uId"))
      );

      const get = await getDocs(check);
      if (get.docs.length) {
        for (let f of get.docs) {
          try {
            await deleteDoc(f.ref);
          } catch (e) {
            console.log(e)
          }
        }
      }

      const getQuiz = await getDocs(quizCheck);
      if (getQuiz.docs.length) {
        for (let f of getQuiz.docs) {
          try {
            await deleteDoc(f.ref);
          } catch (e) {
            console.log(e)
          }
        }
      }

      await setDoc(data, {
        chapterId: id,
        createdAt: Timestamp.now(),
        dueDate: Timestamp.fromDate(dateTime),
        type: 0,
        userId: localStorage.getItem("uId"),
      });

      getData();
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Box
          height="80vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: { xs: "1", md: "flex" },
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            marginBlock: "3rem",
          }}
        >
          {note ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",

                justifyContent: "center",
                alignItems: { xs: "center", md: "start" },
                marginRight: { xs: "0", md: "1rem" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: { xs: "60%", sm: "50%", md: "90%", lg: "100%" },
                }}
              >
                <img
                  src={note.poster}
                  alt=""
                  width="350px"
                  height="auto"
                  style={{
                    margin: "0",
                    padding: "0",
                    boxShadow: "0px 0px 1px 0px rgb(0 0 0 / 50%)",
                    borderRadius: "15px",
                  }}
                />
              </Box>

              <Typography
                variant="body1"
                sx={{
                  fontWeight: "700",
                  pt: "1rem",
                }}
              >
                {note.name}
              </Typography>

              <Typography
                variant="body2"
                sx={{
                  fontWeight: "200",
                  color: "#A5A5A5",
                  pt: { xs: "0.5rem", md: "1rem" },
                  width: { xs: "90%", sm: "22rem" },
                }}
              >
                {note.description}
              </Typography>

              {completed !== -1 ? (
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    completed === 0 ? 0 : (completed / subNote.length) * 100
                  }
                />
              ) : null}

              {completed !== -1 ? (
                <Box
                  sx={{
                    display: "flex",
                    paddingTop: "0.5rem",
                    color: "#717171",
                  }}
                >
                  <Typography variant="body2">
                    {subNote.length} Notes Set /{" "}
                  </Typography>
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {completed} Completed
                  </Typography>
                </Box>
              ) : null}

              <Typography
                variant="body2"
                sx={{
                  pt: "0.5rem",
                }}
              >
                Total Chapters {subNote.length}
              </Typography>

              <Box
                onClick={planner === null ? () => setOpen(true) : null}
                sx={{
                  cursor: "pointer",
                  border: "1px solid #E0E0E0",
                  width: "auto",
                  padding: "8px",
                  borderRadius: "6px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "0.8rem",
                }}
              >
                {planner === null ? <img src={plan} alt="" /> : null}
                <Typography
                  variant="body2"
                  sx={{
                    pl: 1,
                  }}
                >
                  {planner === null
                    ? "Create Study Plan"
                    : `Due date - ${dateFormatDDMMMYYYY(
                      planner.dueDate.toDate()
                    )}`}
                </Typography>
              </Box>
            </Box>
          ) : null}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "60vh",
              overflowY: "auto",
              alignItems: { xs: "center", md: "normal" },
              marginLeft: { xs: "0", md: "1rem" },
              marginTop: { xs: "2rem", md: "0" },
            }}
          >
            {subNote.map((v) => {
              return v.file.pageCount === 0 ? null : (
                <PageViwe
                  key={v.id}
                  value={v.name}
                  id={v.id}
                  link={v.file.url}
                  pageCount={v.file.pageCount}
                  isPremium={v.isPremium}
                />
              );
            })}
          </Box>
        </Box>
      )}
      <PlannerDailog open={open} handleClose={handleClose} />
    </Box>
  );
};

export default Details;

function PageViwe({ value, link, pageCount, id, isPremium }) {
  const navigator = useNavigate();

  const [open, setOpen] = useState(false);

  async function handleCheckout(amount, packageV) {
    if (isUserPremium) {
      return;
    } else {
      let data = qs.stringify({
        cancel_url: cancel_url_var,
        success_url: success_url_var,
        "line_items[0][price_data][currency]": "usd",
        "line_items[0][price_data][product_data][name]": "Subscription",
        "line_items[0][price_data][unit_amount]": amount * 100,
        "line_items[0][quantity]": "1",
        mode: "payment",
        "payment_method_types[0]": "card",
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://api.stripe.com//v1/checkout/sessions",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + stripe_sk,
        },
        data: data,
      };

      axios
        .request(config)
        .then(async (response) => {
          const data = doc(db, "users", auth.currentUser.uid);
          await setDoc(
            data,
            {
              isSubscribe: false,
              subscribetionpackge: packageV,
              payment_intent: response.data.payment_intent,
            },
            { merge: true }
          );
          window.open(response.data.url, "_self");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  return (
    <>
      <Box
        component={"div"}
        onClick={() => {
          if (isPremium && isUserPremium) {
            if (pageCount) {
              navigator(id);
            }
          } else {
            if (isPremium) {
              //
              setOpen(true);
            } else {
              if (pageCount) {
                navigator(id);
              }
            }
          }
        }}
        sx={{
          marginBottom: "0.8rem",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#F7F7F7",
            width: { xs: "250px", sm: "450px" },
            display: "flex",
            justifyContent: "space-between",
            paddingInline: "2rem",
            paddingBlock: "1rem",
            borderRadius: "9px",
            "&:hover": {
              backgroundColor: "#D2E3E3",
            },
          }}
        >
          <Box>
            <Typography
              variant="body1"
              sx={{
                paddingBottom: "0.1rem",
              }}
            >
              {value}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#9E9E9E",
              }}
            >
              {pageCount} Pages
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isPremium && !isUserPremium ? (
              <Lock sx={{ color: "#5a5555" }} />
            ) : (
              <img src={ArrowRight} alt="" style={{}} />
            )}
          </Box>
        </Box>
      </Box>
      {open && (
        <Plan
          handleOnClose={() => {
            setOpen(false);
          }}
          open={open}
          onCall={(v, packageV) => {
            handleCheckout(v, packageV);
          }}
        />
      )}
    </>
  );
}
