import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextareaAutosize,
  Typography,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { Link, TextField } from "@mui/material";
import girl from "./image/girl.png";
import vector from "./image/Vector.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db, storage, auth } from "../../../service/firebase_init";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { onAuthStateChanged } from "firebase/auth";
import DeleteDialog from './DeleteDialog';

const Form = () => {
  const [uId, setUId] = useState(null);
  const navigator = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingEdit, setIsLoadinEdit] = useState(false);
  const [user, setUser] = useState(null);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [inputData, setInputData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    designation: "",
    insName: "",
    bio: "",
  });

  function handleChange(event) {
   
    setFile(event.target.files[0]);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
      setOpen(false);
  };  

  useEffect(() => {
    if (file !== null) {
      setIsLoadinEdit(true);
      const storageRef = ref(storage, `/profile/${Date.now()+file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => { },
        (err) => console.log(err),
         () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then(async(url) => {
            const u = user;
            u.profilePhoto = url;
            setFile(null);
            setUser(u);
            const d = doc(db, "users/" + localStorage.getItem("uId"));
            await setDoc(d, {profilePhoto:url}, { merge: true });
            setIsLoadinEdit(false);
            alert("Profile uploaded");
          });
        }
      );
    }
  }, [file]);

  const handleEdit = async () => {
    setIsLoadinEdit(true);
    const d = doc(db, "users/" + localStorage.getItem("uId"));
    await setDoc(d, inputData, { merge: true });
    setIsLoadinEdit(false);
    alert("Edited profile");
  };

  const handleDelete = async () => {    
    setIsLoadinEdit(true);
    await deleteDoc(doc(db,"users",localStorage.getItem("uId")));
    setIsLoadinEdit(false);
    alert("Profile Deleted Successfully");
    localStorage.removeItem("uId");
    setUId(null);
    navigator("/web");
  };


  const getData = async () => {
    setIsLoading(true);
    const d = doc(db, "users/" + localStorage.getItem("uId"));

    const u = await getDoc(d);

    if (u.data()) {
      setInputData({
        firstName: u.data()["firstName"] ?? "",
        lastName: u.data()["lastName"] ?? "",
        phoneNumber: u.data()["phoneNumber"] ?? "",
        email: u.data()["email"] ?? "",
        designation: u.data()["designation"] ?? "",
        insName: u.data()["insName"] ?? "",
        bio: u.data()["bio"] ?? "",
      });
      setUser(u.data());
    }

    setIsLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {        
        const uid = user.uid;
        setUId(uid);    
        getData();          
      } else {
        // User is signed out
        localStorage.removeItem("uId");
        setUId(null);
        navigator("/web");
      }
    });
  }, []);

  const callBack = (e) => {
    setInputData((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  return !isLoading && user ? (
    <Box>
      <Box
        sx={{
          backgroundColor: "#F2F2F2",
          display: "flex",
          alignItems: "center",

          height: "3.1rem",
          pl: "2rem",
        }}
      >
        <Link
          href=""
          variant="body2"
          sx={{
            color: "#009D9D",
          }}
          onClick={() => navigator("/home")}
        >
          Home
        </Link>
        <Link
          href=""
          underline="none"
          sx={{
            color: "#000",
            px: "0.4rem",
          }}
        >
          /
        </Link>

        <Link
          href=""
          variant="body2"
          sx={{
            color: "#000",
          }}
        >
          Profile
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "center",
          my: { xs: "2rem", md: "3rem" },
          alignItems: { xs: "center", sm: "normal" },
        }}
      >
        <Box
          sx={{
            paddingRight: { xs: "0", sm: "2rem", md: "0" },
          }}
        >
          <Box
            component="label"
            sx={{
              height: "250px",
              width: "250px",
              cursor: "pointer",
            }}
          >
            <img
              src={user?.profilePhoto ?? girl}
              alt=""
              height= "250px"
              width= "250px"
              style={{ margin: "0", padding: "0" }}
            />
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handleChange}
            />
          </Box>

          <Box
          component={"div"}
          onClick={
           ()=>{
            window.open(user?.profilePhoto ?? "")
           }
          }
            sx={{
              cursor:"pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "center", md: "start" },
            }}
          >
            <Typography
            
              variant="body2"
              sx={{
                color: "#000",
                pr: "0.3rem",
                textDecoration: "underline",
              }}
            >
              View Public Profile
            </Typography>
            <img src={vector} alt="" width="10rem" />
          </Box>
        </Box>
        <Box
          sx={{
            marginLeft: { xs: "0rem", md: "1.5rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              paddingTop: { xs: "1rem", sm: "0" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <TextFieldView
                value="First Name"
                callBack={callBack}
                name="firstName"
                dValue={inputData.firstName}
              />
              <TextFieldView
                value="Last Name"
                callBack={callBack}
                name="lastName"
                dValue={inputData.lastName}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <TextFieldView
                value="Phone Number"
                callBack={callBack}
                name="phoneNumber"
                dValue={inputData.phoneNumber}
              />
              <TextFieldView
                value="Email Address"
                callBack={callBack}
                name="email"
                dValue={inputData.email}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <TextFieldView
                value="Designation"
                callBack={callBack}
                name="designation"
                dValue={inputData.designation}
              />
              <TextFieldView
                value="Institute Name"
                callBack={callBack}
                name="insName"
                dValue={inputData.insName}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "center", md: "start" },
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                mr: { xs: "0", md: "1rem" },
                width: { xs: "15rem", md: "41rem" },
              }}
            >
              <Typography variant="body2">Bio</Typography>
              <TextareaAutosize
                aria-label="minimum height"
                minRows={5}
                style={{ width: "100%", resize: "none" }}
                onChange={callBack}
                name="bio"
                value={inputData.bio}
              />
              <Box>
             {
              isLoadingEdit ? <CircularProgress/>:
              <Box>
              <Button
              onClick={handleEdit}
              sx={{
                px: "1.2rem",
                py: "0.5rem",
                mt: "1rem",
                textTransform: "none",
                backgroundColor: "#135050",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#135050",
                },
              }}
            >
              Edit Details
            </Button>
            &nbsp;&nbsp;
            <Button
              onClick={handleClickOpen}
              sx={{
                px: "1.2rem",
                py: "0.5rem",
                mt: "1rem",
                textTransform: "none",
                backgroundColor: "#ff0044",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#ff0044",
                },
              }}
            >
              Delete Profile
            </Button>
            <DeleteDialog open={open} handleClose={handleClose} confirmFunction={handleDelete}/>
            </Box>
            
             }
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
   <Box sx={{
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
   }}>
     <CircularProgress />
   </Box>
  );
};

export default Form;
function TextFieldView({ value, name, callBack, dValue }) {
  return (
    <Box
      sx={{
        mr: { xs: "0", md: "1rem" },
        pb: 2,
      }}
    >
      <Typography variant="body2">{value}</Typography>
      <TextField
        name={name}
        onChange={callBack}
        value={dValue}
        id=""
        sx={{
          background: "#F3F3F3",
          borderRadius: "0.5rem",
          width: { xs: "15rem", md: "20rem" },
        }}
        InputProps={{
          style: { height: "2.7rem" },
        }}
      />
    </Box>
  );
}
