import {
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import note from "./image/Note.png";
import { collection, deleteDoc, doc, getDoc, getDocs, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../../service/firebase_init";

const Notification = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const getData = async () => {
    setIsLoading(true);
    
    const d = collection(
      db,
      "users/" + localStorage.getItem("uId") + "/notification"
    );
    const dd = await getDocs(d);
    const t = [];
    if (dd.docs.length) {
      for (let dc of dd.docs) {
        if (dc.data()["type"] === 0) {
          try {
            const u = doc(db, "users/" + localStorage.getItem("uId"));
            const uu = await getDoc(u);
            const tt = {
              data: dc.data(),
              user: uu.data(),
            };
            t.push(tt);
          } catch (e) {}
        } else if (dc.data()["type"] === 1) {
          try {
            const u = doc(
              db,
              "notes/" + dc.data()["notificationData"]["chapterId"]
            );
            const uu = await getDoc(u);
            const tt = {
              data: dc.data(),
              notes: uu.data(),
            };
            t.push(tt);
          } catch (e) {}
        }
      }
      setData([...t]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return isLoading ? (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="200px"
      width="300px"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card sx={{}}>
        <Box
          sx={{
            padding: "10px",
          }}
        >
          

          {data.map((v, index) => {
            if (v.data.type === 0) {
              return <NotificationViwe key={index} value={v} getData={getData} />;
            }else if(v.data.type === 1) {
              return <NotesViwe key={index} value={v}/>
            }
          })}
          {
            data.length === 0 ? <Typography>No data found</Typography> :null
          }
        </Box>
      </Card>
    </Box>
  );
};

export default Notification;

function ButtonViwe({ value }) {
  return (
    <Box>
      <Button
        sx={{
          color: "#000",
          textTransform: "none",
          backgroundColor: "#F4F4F4",
          "&:hover": {
            backgroundColor: "#135050",
            color: "#fff",
          },
        }}
      >
        {value}
      </Button>
    </Box>
  );
}

function NotificationViwe({ value,getData }) {

  const accept = async ()=>{

    const df = doc(db,"users/"+localStorage.getItem("uId")+"/follow/"+value.data.notificationData.userId);
    await setDoc(df,{
      createdAt:Timestamp.now(),
      status:true,
      userId:value.data.notificationData.userId
    });
    await deleteF();
   
  }

  const deleteF = async()=>{
  const d = doc(db,"users/"+localStorage.getItem("uId")+"/notification/"+value.data.id);
  const dd = await getDoc(d);
  await deleteDoc(dd.ref);
  getData();
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#FAFAFA",
          marginTop: "1rem",
          padding: "10px",
        }}
      >
        <Box>
          <Avatar src={value.user.profilePhoto} alt="" />
        </Box>
        <Box
          sx={{
            marginBottom: "0.5rem",
            marginLeft: "0.5rem",
          }}
        >
          <Box>
            <Typography
              variant="body2"
              sx={{
                color: "#A2A2A2",
              }}
            >
              You have a new request from
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontWeight: "500",
              }}
            >
              {value.user.firstName + " " + value.user.lastName}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "0.5rem",
            }}
          >
            <Box>
              <Button
              onClick={()=>accept()}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#363636",
                  py: "0.3rem",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#135050",
                    color: "#fff",
                  },
                }}
              >
                Accept
              </Button>
            </Box>
            <Box
              sx={{
                marginInline: "0.5rem",
              }}
            >
              <Button
              onClick={()=>deleteF()}
                sx={{
                  textTransform: "none",
                  backgroundColor: "#363636",
                  py: "0.3rem",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#135050",
                    color: "#fff",
                  },
                }}
              >
                Decline
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function NotesViwe({value}) {

  const getDifference = (fd)=>{
    var date1 = new Date();
    var date2 = fd;
      
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();
      
    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 60 * 60 * 24);
      
    //To display the final no. of days (result)
   return "You reach your due date in "
               + Math.round(Difference_In_Days) +"  Days For";
  }

  const getNumber = (v)=>{

    if(v.data.type === 0){
      return  v.notes.notesCount +" notes";
    }else if(v.data.type === 1){
      return  v.notes.quizCount +" quizs";
    }else {
      return  v.notes.flashCardCount +" cards";
    }

  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#FAFAFA",
          marginTop: "1rem",
          padding: "10px",
        }}
      >
        <Box>
          <img src={note} alt="" />
        </Box>
        <Box
          sx={{
            marginLeft: "0.5rem",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              color: "#A2A2A2",
            }}
          >
           {getDifference(value.data.notificationData.dueDate.toDate())}
          </Typography>
          <Typography variant="body2">{value.notes.name} - {getNumber(value)}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
