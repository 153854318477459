import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../service/firebase_init";
import { DoneAll } from "@mui/icons-material";

function ReportData({ open, handleClose }) {
  const [data, setData] = useState({
    data: [],
    isLoading: true,
    selectedId:null
  });

  const getData = async () => {
    setData((p) => ({ ...p, isLoading: true }));
    const d = query(collection(db, "reportResion",),where("status", "==", 1));

    const dd = await getDocs(d);

    if (dd.docs.length) {
       
      setData({
        data: dd.docs.map((v) => ({ ...v.data(), id: v.id })),
        isLoading: false,
      });
      
    } else {
      setData((p) => ({ ...p, isLoading: false }));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const report = async () => {
    const data = "";
  };

  return (
    <Dialog open={open} onClose={() => handleClose(0)}>
      <DialogTitle>Select you report reason</DialogTitle>
      <DialogContent
        sx={{
          height: "400px",
          width: "100%",
          overflowY: "auto",
        }}
      >
        {data.isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : data.data.length ? (
          data.data.map((v) => (
             <ListItemButton key={v.id} onClick={() => {
               handleClose(1,v.reason);
            }}>{v.reason} </ListItemButton>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>No reason found</Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>        
        <Button onClick={() => handleClose(0)} variant="text" size="small">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReportData;
