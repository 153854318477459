import { ArrowBack } from "@mui/icons-material";
import { Box, CircularProgress, IconButton, TextField } from "@mui/material";
import { doc, getDoc, query, setDoc, Timestamp } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf/dist/esm/entry.webpack";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../../../service/firebase_init";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfViewer() {
  const navigator = useNavigate();

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1);

  const handler = (e) => {
    if (e.key === "ArrowLeft") {
      goToPrevPage();
    }
    if (e.key === "ArrowRight") {
      goToNextPage();
    }
  };

  const { id, nId } = useParams();

  const addAnswer = async (v) => {
    const d = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/noteStatus/" + nId
    );

    await setDoc(
      d,
      {
        createdAt: Timestamp.now(),
        currentPage: v,
        time: 0,
        compliteAt: Timestamp.now(),
        notesId: id,
        subjectId: nId,
        totalPage: data.file.pageCount,
        userId: localStorage.getItem("uId"),
      },
      { merge: true }
    );
  };

  const getData = async () => {
    setIsLoading(true);
    const data = query(doc(db, "notes/" + id + "/subChapters/", nId));
    const d = await getDoc(data);
    if (d.data()) {
      setData(d.data());
    }
    setIsLoading(false);
  };

  const getSubNote = async () => {
    const data = doc(
      db,
      "users/" + localStorage.getItem("uId") + "/noteStatus/" + nId
    );
    const d = await getDoc(data);

    if (d?.data()) {
      setPageNumber(d.data().currentPage);
    }
  };

  useEffect(() => {
    getData();
    getSubNote();
  }, []);

  useEffect(() => {
    if (data) {
      addAnswer(pageNumber);
    }
  }, [pageNumber]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const goToPrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const goToNextPage = async () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  return isLoading ? (
   <Box 
   sx={{
    width:"100%",
    height:"80vh",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
   }}
   >
     <CircularProgress />
   </Box>
  ) : (
    <div style={{ height: "100vh", overflowY: "scroll" }}>
      <div className="nav">
        <nav style={{ marginLeft: "20px", display: "flex" }}>
          <IconButton onClick={() => navigator(-1)}>
            <ArrowBack />
          </IconButton>
          <p className="nav_controller">
            Page {pageNumber} of {numPages}
          </p>
          <TextField helperText="Enter page number" label="Page Number" sx={{width:"150px",ml:2,mt:2}} type="number" onChange={(e)=>{

            if(e.target.value > 0 && e.target.value < numPages){
              setPageNumber(+e.target.value);
            }
          }}
           variant="outlined" size="small"/>
        </nav>
        <nav>
          <p className="nav_controller">
            <button
              className="nav_controller_btn"
              onClick={() => {
                if (scale > 0.25) {
                  setScale((scale * 100 - 25) / 100);
                }
              }}
            >
              -
            </button>
            Zoom {scale * 100} %
            <button
              className="nav_controller_btn"
              onClick={() => {
                setScale((scale * 100 + 25) / 100);
              }}
            >
              +
            </button>
          </p>
        </nav>
      </div>
      <div
        className="pdf_body"
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            zIndex: 100,
            position: "fixed",
            top: "45vh",
            left: 30,
          }}
        >
          <p className="nav_controller">
            <a href={"#" + pageNumber}>
              <button
                className="nav_controller_btn"
                style={{
                  padding: "20px 50px",
                }}
                onClick={goToPrevPage}
              >
                ←
              </button>
            </a>
          </p>
        </div>

        <div
          style={{
            position: "fixed",
            top: "45vh",
            right: 50,
            zIndex: 100,
          }}
        >
          <p className="nav_controller">
            <a href={"#" + pageNumber}>
              <button
                className="nav_controller_btn"
                style={{
                  padding: "20px 50px",
                }}
                onClick={goToNextPage}
              >
                →
              </button>
            </a>
          </p>
        </div>

        <Document
          file={data.file.url}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={console.error}
        >
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
    </div>
  );
}

export default PdfViewer;
