/* eslint-disable no-unused-vars */
import React from "react";
import circle from "./image/Circle.png";
import Typography from "@mui/material/Typography";
import { Box, Button, Grid, Paper, Radio } from "@mui/material";
import Switch from "@mui/material/Switch";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const label = { inputProps: { "aria-label": "Color switch demo" } };

const QuizPagefour = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBlock: "2rem",
      }}
    >
      <Box>
      <Box
      >
        <MockTestViwe value="Create a Mock Test" subtitle="Q-Bank Mode" />
        </Box>
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#FBFBFB",
            borderRadius: "10px",
            paddingInline: "1.5rem",
            paddingBlock: "0.5rem",
            width: { xs: "100%", sm: "500px", md: "800px" },
            flexWrap: "wrap",
            mt: 0.5,

          }}
        >
          <CustomeLayOut lable="CAT ( Adaptive Test )">
            <ColorSwitches />
          </CustomeLayOut>
          <Box
            sx={{
              display: "flex",
              marginInline: { xs: "0", sm: "1.5rem" },
            }}
          >
            <CustomeLayOut lable="Tutorial">
              <ColorSwitches />
            </CustomeLayOut>
          </Box>
          <CustomeLayOut lable="Timer">
            <ColorSwitches />
          </CustomeLayOut>
        </Box>
      </Box>
      <Box>
        <MockTestViwe subtitle="Organization Type" />
        <Box
          sx={{
            display: "flex",
            backgroundColor: "#FBFBFB",
            borderRadius: "10px",
            paddingInline: "1.5rem",
            paddingBlock: "0.3rem",
            width: { xs: "100%", sm: "500px", md: "800px" },
            flexWrap: "wrap",
            mt: 0.5,
            
          }}
        >
          <CustomeLayOut value="Subject or System">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut value="Client Need Areas">
            <RedioButtonViwe />
          </CustomeLayOut>
        </Box>
      </Box>
      <Box >
        <MockTestViwe subtitle="Choose Subject" />
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            flexWrap: "wrap",
            backgroundColor: "#FBFBFB",
            width: { xs: "280px", sm: "500px", md: "800px" },
            borderRadius: "10px",
            paddingLeft: "1.5rem",
            paddingBlock: "0.3rem",
            mt: 0.5,
          }}
        >
          <CustomeLayOut subtitle="Child Health">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Maternal & Newborn Health">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Fundamentals">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Pharology ">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Fundamentals ">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Pharology ">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Child Health">
            <RedioButtonViwe />
          </CustomeLayOut>
          <CustomeLayOut subtitle="Maternal & Newborn Health">
            <RedioButtonViwe />
          </CustomeLayOut>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#135050",
            borderRadius: "100px",
            paddingInline: "0.5rem",
            marginLeft:{xs:"0.5rem",md:"0"},
            width: { xs: "50%", sm: "30%", md: "20%" },
            mt: "1rem",
          }}
        >
          <Button>
            <Typography
              variant="body2"
              sx={{
                textTransform: "none",
                pr: "0.5rem",
              }}
            >
              Create Test
            </Typography>
            <img src={circle} alt="" />
          </Button>
        </Box>
      </Box>
      
    </Box>
  );
};

export default QuizPagefour;

function ColorSwitches() {
  return (
    <Box>
      <Switch {...label} defaultChecked color="secondary" size="small" />
    </Box>
  );
}
function MockTestViwe({ value, subtitle }) {
  return (
    <Box
    sx={{
      paddingLeft:{xs:"0.5rem",md:"0"}
    }}
    >
      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: "500",
            marginBottom: "1rem",
          }}
        >
          {value}
        </Typography>
      </Box>
      <Box>
      <Typography variant="body2" >{subtitle}</Typography>
      </Box>
    </Box>
  );
}

function CustomeLayOut({ children, lable, value, subtitle, flag = 1 }) {
  return (
    <Box display="flex">
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="body1">{lable}</Typography>
        {children}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="body1">{value}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}
function RedioButtonViwe() {
  return <Radio color="secondary" size="small" />;
}

function CardViwe({ value, children }) {
  return (
    <Box sx={{
       
    }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
        <Typography
          variant="body1"
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {value}
        </Typography>
      </Box>
    </Box>
  );
}
