import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useState } from "react";
import TextFieldView from "../../Components/TextField/TextFieldView";
import LoadingButtonView from "../../Components/Button/LoaderButton";
import { Timestamp, doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../service/firebase_init";
import { APP_NAME_SMALL, APPLE_URL, ANDROID_URL } from "../../App.js";

function Ragister() {
  const [firstname, setFirstname] = useState("");
  const [loading, setLoading] = useState(false);
  const [lastname, setLastname] = useState("");
  const [schoolname, setSchoolName] = useState("");
  const navigation = useNavigate();
  const [urlSearchPerm,setUrlPerm] = useSearchParams();
  const [education, setEducation] = React.useState("First Year");

  const handleEducation = (event) => {
    setEducation(event.target.value);
  };

  const handleLogin = async () => {
    if (!firstname) {
      alert("Please fill first name");
    } else if (!lastname) {
      alert("Please fill last name");
    } else if (!schoolname) {
      alert("Please fill school name");
    } else {
      setLoading(true);
      const data = doc(
        db,
        "users",
        auth.currentUser.uid,
      );

      await setDoc(data, {
        bio:"",
        blockList:[],
        educationalYear:education,
        email:auth?.currentUser?.email ?? "",
        firstName:firstname,
        followers:0,
        following:0,
        isDelete: false,
        createdAt: Timestamp.now(),
        lastLoginTime: Timestamp.now(),
        isLoggedIn: true,
        isNotification: true,
        userId: localStorage.getItem("uId"),
        lastName:lastname,
        profilePhoto:null,
        progress:0,
        schoolName:schoolname,
        status:1,
        subscribetiondate:null,
        subscribetionpackge:"",
        userId: auth.currentUser.uid,
      });
      localStorage.setItem("uId",auth.currentUser.uid);

      const amount = urlSearchPerm.get("amount");
          const packageV = urlSearchPerm.get("package");
          // com.nursingfocus.yearly

          let q = "";
          if(amount && packageV){
            const a = amount ? "?amount="+amount : "";
            const p = packageV ? "&package="+packageV : "";
            q = a+p;
          }

      navigation("/home"+q);
      setLoading(false);
    }
  };

  const handleFirstName = (v) => {
    setFirstname(v);
  };

  const handleLastName = (v) => {
    setLastname(v);
  };

  const handleSchoolName = (v) => {
    setSchoolName(v);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "#09383E",
          width: "400px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 2.5,
          px: 5,
          border: "solid",
          borderRadius: "20px",
          borderColor: "background.paper",
          borderWidth: "0.5px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
            marginTop: 2,
          }}
        >
          <img
            src="logoWhite.png"
            alt="logo"
            style={{ height: "35px", color: "white", marginRight: "10px" }}
          />          
          <Typography
            sx={{
              color: "white", 
              fontWeight: "bold",
              fontSize: "150px",
            }}
          >
            {APP_NAME_SMALL}
          </Typography>
        </Box>
        <Typography
          variant="h6"
          sx={{
            mt: 1.3,
            mb: 0.3,
            textAlign: "center",
            fontWeight: "bold",
            color: "white",
          }}
        >
          Create Account !!
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: "center", color: "white" }}
        >
          glad to see you there again.
        </Typography>

        <TextFieldView
          label="First name"
          value={firstname}
          onchange={handleFirstName}
          sx={{ mt: 1, mb: 1, width: "100%" }}
        />

        <TextFieldView
          label="Last name"
          value={lastname}
          onchange={handleLastName}
          sx={{ mb: 1, width: "100%" }}
        />

        <TextFieldView
          label="School name"
          value={schoolname}
          onchange={handleSchoolName}
          sx={{ mb: 0.5, width: "100%" }}
        />

        <FormControl
        fullWidth
        sx={{ m: 1,  backgroundColor:"#286F6F",borderRadius:2 }} size="small">
         
          <Select
            placeholder="Education Year"
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={education}
            label="Education Year"
            onChange={handleEducation}
          >
            <MenuItem value="First Year">First Year</MenuItem>
            <MenuItem value="Second Year">Second Year</MenuItem>
            <MenuItem value="Third Year">Third Year</MenuItem>
            <MenuItem value="Graduated">Graduated</MenuItem>
          </Select>
        </FormControl>

        <LoadingButtonView
          loading={loading}
          lable="Register"
          onClick={() => handleLogin()}
          variant="contained"
          sx={{
            width: "100%",
            mt: 1,
            height: "40px",
            backgroundColor: "white",
          }}
        />
      </Paper>
    </Box>
  );
}

export default Ragister;

function SocialContainer({ icon }) {
  return (
    <Box
      sx={{
        height: "35px",
        width: "35px",
        borderRadius: "8px",
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mx: 0.8,
        cursor: "pointer",
      }}
    >
      <img src={icon} alt="icon" style={{ height: "20px", width: "20px" }} />
    </Box>
  );
}
