import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import notes from "./image/notes.png";
import cards from "./image/cards.png";
import frame from "./image/frame.png";
import videolibrary from "./image/video-library.png";
import { useNavigate } from "react-router-dom";

const Card = () => {
  const navigator = useNavigate();


  return (
    <Box sx={{ flexGrow: 1, }}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={4} lg={4} component={"div"} onClick={()=>navigator("notes")}>
          <BoxView images={notes} value="Notes" />
        </Grid>
        <Grid item xs={12} sm={4} lg={4} component={"div"}  onClick={()=>navigator("flash-card")}>
          <BoxView images={cards} value="Flash Cards" />
        </Grid >
        <Grid
          item
          xs={12}
          sm={4}
          lg={4}
        
          component={"div"}  onClick={()=>navigator("quiz")}
        >
          <BoxView images={frame} value="Quiz" />
        </Grid>
        
      </Grid>
      
    </Box>
  );
};

export default Card;
function BoxView({ images, value }) {
  return (
    <Grid
      sx={{
        cursor:"pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap",
        border: "2px solid #E5E5E5",
        borderRadius: "5px",
        marginTop: "1rem",
        marginInline: "3rem",
        padding: "1rem",
        "&:hover": {
          backgroundColor: "#EFFFFE",
          border: "2px solid #91C8C4",
        },
      }}
    >
      <img src={images} alt="" width="40px" height="40px" />
      <Typography variant="body1" sx={{textAlign:"center"}}>{value}</Typography>
    </Grid>
  );
}
