/* eslint-disable no-unused-vars */
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { dateFormatDDMMMYYYY } from "../../service/date";
import { db, auth } from "../../service/firebase_init";
import PlannerDailog from "../Dailog/PlannerDailog";
import { onAuthStateChanged } from "firebase/auth";

const QuizCrad = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [quiz, setQuiz] = useState([]);
  let [selectedId, setSelectedId] = useState(0);
  const [uId, setUId] = useState(null);
  const navigator = useNavigate();

  const getNotes = async () => {
    const data = query(
      collection(db, "notes"),
      where("hideQuiz", "==", true),
      orderBy("index", "asc")
    );

    const d = await getDocs(data);
    setIsLoading(true);
    if (d?.docs?.length) {
      const t = [];
      for (const [index, i] of (d.docs.length ? d.docs : []).entries()) {
        if(!i.data().hasQuiz){
          continue;
        }
        t.push(i.data());
        const check = query(
          collection(
            db,
            "users/" + localStorage.getItem("uId") + "/planner"
          ),
          where("chapterId", "==", i.id),
          where("userId", "==", localStorage.getItem("uId"))
        );
        const get = await getDocs(check);

        const datas = collection(db, "notes/" + i.id + "/subChapters");
        const d = await getDocs(datas);

        t[t.length-1].cards = d.docs.length;

        if (get.docs.length) {
          const quizCheck = query(
            collection(
              db,
              "users/" + localStorage.getItem("uId") + "/quizStatus"
            ),
            where("notesId", "==", i.id),
            where("userId", "==", localStorage.getItem("uId"))
          );
          const gets = await getDocs(quizCheck);

          t[t.length-1].planner = get.docs[0].data();
          t[t.length-1].completed = gets.docs.length;
        } else {
          t[t.length-1].completed = -1;
        }
      }

      setQuiz(t);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {        
        const uid = user.uid;
        setUId(uid);    
        getNotes();          
      } else {
        // User is signed out
        localStorage.removeItem("uId");
        setUId(null);
        navigator("/web");
      }
    });
  }, []);

  const handleClose = async (v = 0, dateTime) => {
    setOpen(false);
    if (v) {
      const data = doc(
        db,
        "users",
        localStorage.getItem("uId"),
        "planner",
        selectedId
      );

      const check = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/planner"
        ),
        where("chapterId", "==", selectedId),
        where("userId", "==", localStorage.getItem("uId"))
      );
      const quizCheck = query(
        collection(
          db,
          "users/" + localStorage.getItem("uId") + "/quizStatus"
        ),
        where("notesId", "==", selectedId),
        where("userId", "==", localStorage.getItem("uId"))
      );

      const get = await getDocs(check);
      if (get.docs.length) {
        for (let f of get.docs) {
          try {
            await deleteDoc(f.ref);
          } catch (e) {
            console.log(e)
          }
        }
      }

      const getQuiz = await getDocs(quizCheck);
      if (getQuiz.docs.length) {
        for (let f of getQuiz.docs) {
          try {
            await deleteDoc(f.ref);
          } catch (e) {
            console.log(e)
          }
        }
      }

      await setDoc(data, {
        chapterId: selectedId,
        createdAt: Timestamp.now(),
        dueDate: Timestamp.fromDate(dateTime),
        type: 1,
        userId: localStorage.getItem("uId"),
      });

      getNotes();
    }
  };

  return isLoading ? (
    <Box
      height="80vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ flexGrow: 1, marginBlock: "1.5rem" }}>
      <Grid container spacing={0}>
        {quiz.map((v) => {
          return (
            <Grid key={v.id} item xs={12} sm={6} lg={4} component={"div"}>
              <FlashCradView
                setSelectedId={setSelectedId}
                v={v}
                setOpen={setOpen}
              />
            </Grid>
          );
        })}
      </Grid>
      <PlannerDailog open={open} handleClose={handleClose} />
    </Box>
  );
};

export default QuizCrad;
function FlashCradView({ v, setSelectedId, setOpen }) {
  const navigator = useNavigate();

  return (
    <Grid
      sx={{
        backgroundColor: "rgba(21, 66, 63, 0.02);",
        border: "1px solid #135050",
        borderRadius: "13px",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        paddingLeft: "1.5rem",
        paddingBlock: "0.8rem",
        marginInline: { xs: "1rem", md: "2rem", lg: "3rem" },
        marginBlock: "1rem",
        cursor: "pointer",
        position: "relative",
        "&:hover": {
          backgroundColor: "#EFFFFE",
        },
      }}
    >
      <Box
        sx={{
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          position: "absolute",
        }}
        onClick={() => navigator(v.id)}
      ></Box>

      <Box >
        <Typography
          variant="body1"
          sx={{
            fontWeight: "600",
          }}
        >
          {v.name}
        </Typography>
        {v.completed !== -1 ? (
          <Typography
            variant="body2"
            sx={{
              marginTop: "0.8rem",
              color: "#717171",
            }}
          >
            {v.cards} Quiz Set / {v.completed} Completed
          </Typography>
        ) : null}
      </Box>
      <Button
        sx={{
          border: "1px solid #E0E0E0",
          width: "auto",
          padding: "8px",
          borderRadius: "6px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "0.8rem",
        }}
        onClick={() => {
          if(v.planner === null || v.planner === undefined){
            setSelectedId(v.id);
            setOpen(true);
          }
        }}
      >
        <Typography
          variant="body2"
          sx={{
            pl: 1,
          }}
        >
          {(v.planner === null || v.planner === undefined) ? "Create Study Plan" : `Due date - ${dateFormatDDMMMYYYY(v.planner.dueDate.toDate())}`}  
        </Typography>
      </Button>
    </Grid>
  );
}
