/* eslint-disable no-unused-vars */
import { Box, ThemeProvider } from "@mui/material";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Home/Navbar";
import { theme } from "./utils/Theme";
import Footer from "./Components/Home/Footer";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import HomePage from "./Pages/Home/HomePage";
import FormLink from "./Pages/FormLink/FormLinkPage";
import FormPage from "./Pages/Form/FormPage";
import NotePage from "./Pages/Notes/NotePage";
import FlashCardPage from "./Pages/FlashCard/FlashCard";
import FlashCardDetails from "./Pages/FlashCard/FlashCardDetails";
import NotesDetailsPage from "./Pages/Notes/NotesDetailsPage";
import CardDetailsView from "./Pages/FlashCard/CardDetailsView";
import VideosPage from "./Pages/Videos/VideosPage";
import VideosDetails from "./Pages/Videos/VideosDetails";
import QuizPage from "./Pages/Quiz/QuizPage";
import QuizCreate from "./Pages/Quiz/QuizCreate";
import QuizDetails from "./Pages/Quiz/QuizDetails";
import QuizSet from "./Pages/Quiz/QuizSet";
import QuizResult from "./Pages/Quiz/QuizResult";
import PlannerPage from "./Pages/Planner/PlannerPage";
import LoginAuth from "./Pages/Auth/Login";
import { onAuthStateChanged } from "firebase/auth";
import { auth, db } from "./service/firebase_init";
import PdfViewer from "./Components/Notes/Pdf/PdfViewer";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import Ragister from "./Pages/Auth/Ragister";
import { doc, getDoc, setDoc } from "firebase/firestore";
import Status from "./Pages/Payment/Status";
import axios from "axios";
import qs from "qs";
import { stripe_sk } from "./variables";
import ConstantsList from "./service/constants";

const hostname = window.location.hostname;
const config = ConstantsList[hostname];
export let ANDROID_URL = config.ANDROID_URL;
export let APPLE_URL = config.APPLE_URL;
export let APP_NAME = config.APP_NAME;
export let APP_NAME_SMALL = config.APP_NAME_SMALL;
export let APP_ID = config.APP_ID;
export let isUserPremium = false;
export let bearerToken = "uuid";
function App() {
  const { pathname } = useLocation();
  const [urlSearchPerm, setUrlSearchPerm] = useSearchParams();
  const [uId, setUId] = useState(null);

  const navigator = useNavigate();

  useEffect(() => {
    TimeAgo.addDefaultLocale(en);
    document.title = APP_NAME_SMALL+" Web";
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {        
        
        const uid = user.uid;
        setUId(uid);
        const data = doc(db, "users", uid);

        const d = await getDoc(data);

        if (d.exists()) {
          const data = d.data();
          if (!data.customerID) {
            

            let dataC = qs.stringify({});
            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: "https://api.stripe.com//v1/customers",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: "Bearer "+stripe_sk,
              },
              data: dataC,
            };

            axios
              .request(config)
              .then(async (response) => {
                const d = doc(db, "users/" + localStorage.getItem("uId"));
                await setDoc(
                  d,
                  { customerID: response.data.id },
                  { merge: true }
                );
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if ((data.isSubscribe  || data.isSubscribeByAdmin) && data.subscribetiondate) {
            // need to check paymentMode = v2
            if (data.paymentMode === "v2") {
              try{
                const ds = await axios.get(
                  "https://api.stripe.com//v1/subscriptions/" + data.subscriptionID,
                  {
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                      Authorization: "Bearer "+stripe_sk,
                    },
                  }
                );
  
                if (ds.data) {
                  isUserPremium = ds.data.plan.active;
                } else {
                  isUserPremium = false;
                }
              } catch (err) {
                console.error("Error response:");
                console.error(err.response.data);    // ***
                console.error(err.response.status);  // ***
                console.error(err.response.headers); // ***
                isUserPremium = false;
              } finally {
                console.log("call completed");
              }
              
            } else {
              if (data.subscribetionpackge && data.subscribetionpackge.length > 0) {
                let month = 0;
                if (data.subscribetionpackge === "com.nursingfocus.yearly") {
                  month = 12;
                } else if (data.subscribetionpackge === "com.nursingfocus.six_month") {
                  month = 6;
                } else if (data.subscribetionpackge === "com.nursingfocus.monthly") {
                  month = 1;
                } else {
                  month = 0;
                }
                const td = new Date(data.subscribetiondate.toDate());
                const dd = td.setMonth(td.getMonth() + month);

                const cd = Date.now();
                if (dd >= cd) {
                  isUserPremium = true;
                } else {
                  isUserPremium = false;
                }
              } else {
                isUserPremium = false;
              }
            }
          } else {
            if(data.isSubscribeByAdmin){
              isUserPremium = true;
            }else{
              isUserPremium = false;
            }
          }
          localStorage.setItem("uId", uid);
          const amount = urlSearchPerm.get("amount");
          const packageV = urlSearchPerm.get("package");
          // com.nursingfocus.yearly

          let q = "";
          if (amount && packageV) {
            const a = amount ? "?amount=" + amount : "";
            const p = packageV ? "&package=" + packageV : "";
            q = a + p;
          }

          if (pathname === "/") {
            navigator("/home");
          } else if (!pathname.includes("web")) {
            navigator(pathname + q);
          } else {
            navigator("/home" + q);
          }
        } else {
          isUserPremium = false;
          localStorage.removeItem("uId");
          setUId(null);

          const amount = urlSearchPerm.get("amount");
          const packageV = urlSearchPerm.get("package");
          // com.nursingfocus.yearly

          let q = "";
          if (amount && packageV) {
            const a = amount ? "?amount=" + amount : "";
            const p = packageV ? "&package=" + packageV : "";
            q = a + p;
          }

          navigator("/web/register" + q);
        }
      } else {
        // User is signed out
        localStorage.removeItem("uId");
        setUId(null);

        const amount = urlSearchPerm.get("amount");
        const packageV = urlSearchPerm.get("package");
        // com.nursingfocus.yearly

        let q = "";
        if (amount && packageV) {
          const a = amount ? "?amount=" + amount : "";
          const p = packageV ? "&package=" + packageV : "";
          q = a + p;
        }

        navigator("/web" + q);
      }

    });
  }, []);
  return (
    <ThemeProvider theme={theme("light")}>
      <Box>
        <Home />
        {!(
          pathname === "/web" ||
          pathname === "/web/" ||
          pathname === "/web/register"
        ) ? (
          <Navbar />
        ) : null}
        <Routes>
          <Route path="/web/" element={<LoginAuth />} />
          <Route path="/web/register" element={<Ragister />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/home/form-link" element={<FormLink />} />
          <Route path="/home/form" element={<FormPage />} />
          <Route path="/home/notes" element={<NotePage />} />
          <Route path="/home/notes/:id" element={<NotesDetailsPage />} />
          <Route path="/home/notes/:id/:nId" element={<PdfViewer />} />
          <Route path="/home/flash-card" element={<FlashCardPage />} />
          <Route path="/home/flash-card/:id" element={<FlashCardDetails />} />
          <Route path="/home/flash-card/:id/:fId" element={<CardDetailsView />} />
          <Route path="/home/videos/" element={<VideosPage />} />
          <Route path="/home/videos/:id" element={<VideosDetails />} />
          <Route path="/home/quiz" element={<QuizPage />} />
          <Route path="/home/quiz/:id" element={<QuizDetails />} />
          <Route path="/home/quiz/:id/:qId" element={<QuizSet />} />
          <Route path="/home/quiz/:id/:qId/result" element={<QuizResult />} />
          <Route path="/home/quiz-create" element={<QuizCreate />} />
          <Route path="/home/planner" element={<PlannerPage />} />
          <Route path="/home/payment-status" element={<Status />} />
        </Routes>

        {pathname !== "/web" ? <Footer /> : null}
      </Box>
    </ThemeProvider>
  );
}

export default App;
