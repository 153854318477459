import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import Shape from "./Shape.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { stripe_sk } from "../variables";

const Plan = ({ handleOnClose, open, onCall, sLoader }) => {
  const [row, setRow] = useState(null);
  const [sLdr, setSLoader] = useState(sLoader);
  const getData = async () => {
    const { data } = await axios.get("https://api.stripe.com//v1/products", {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + stripe_sk,
      },
    });
    const { data: dataPrice } = await axios.get(
      "https://api.stripe.com//v1/prices",
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: "Bearer " + stripe_sk,
        },
      }
    );
    if (data && data.data && dataPrice && dataPrice.data) {
      const month = dataPrice.data.filter((v) => v.product === data.data.filter((j) => j.name === "NR-Monthly")[0].id)[0].unit_amount;
      const yearly = dataPrice.data.filter((v) => v.product === data.data.filter((j) => j.name === "NR-Yearly")[0].id)[0].unit_amount;
      const sixMonth = dataPrice.data.filter((v) => v.product === data.data.filter((j) => j.name === "NR-Six Month")[0].id)[0].unit_amount;
      setRow({
        month, yearly, sixMonth
      });
    }
  };
  useEffect(() => {
    getData();
  }, [])
  const PackData = [
    {
      value: "Yearly Pack",
      subtitle1: "Helped over 100,000 TOEFL students",
      subtitle2:
        "96% PassRate 3000 Questions & Explanation 600+ Study Guide & Cheat sheets",
      subtitle3: "Track your progress with Study planner",
      subtitle4: "Mobile & Website access",
      price: "At Just USD 59.99",
      valueP: 59.99,
      packageV: "com.nursingfocus.yearly",
      t: "yearly"
    },
    {
      value: "Six Month Pack",
      subtitle1: "Helped over 100,000 TOEFL students",
      subtitle2:
        "96% PassRate 3000 Questions & Explanation 600+ Study Guide & Cheat sheets",
      subtitle3: "Track your progress with Study planner",
      subtitle4: "Mobile & Website access",
      price: "At Just USD 34.99",
      valueP: 34.99,
      packageV: "com.nursingfocus.six_month",
      t: "sixMonth"
    },
    {
      value: "Monthly Pack",
      subtitle1: "Helped over 100,000 TOEFL students",
      subtitle2:
        "96% PassRate 3000 Questions & Explanation 600+ Study Guide & Cheat sheets",
      subtitle3: "Track your progress with Study planner",
      subtitle4: "Mobile & Website access",
      price: "At Just USD 6.99",
      valueP: 6.99,
      packageV: "com.nursingfocus.monthly",
      t: "month"
    },
  ];

  return (
    <Dialog fullWidth open={open} onClose={handleOnClose}>
      <DialogTitle>Choose Your plan</DialogTitle>
      <DialogContent>
        <Box>
          <Box>
            {
              (row && !sLoader) ?
                <Box
                  sx={{
                    backgroundColor: "#135050",
                    borderRadius: "10px",
                    pb: 5,
                  }}
                >
                  <Box
                    sx={{
                      px: { xs: 1, sm: 5, md: 3, xl: 8 },
                    }}
                  >

                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Grid
                        container
                        spacing={{
                          xs: 1,
                          md: 1,
                          lg: 1,
                          xl: 1,
                        }}
                      >
                        {PackData.map((n, i) => {
                          return (
                            <GridView key={i}>
                              <PackView setSLoader={() => setSLoader(true)} {...n} onCall={onCall} row={row} />
                            </GridView>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
                : <Box sx={{
                  flex: 1,
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <CircularProgress />
                </Box>
            }


          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Plan;

function PacktextView({ subtitle }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        pt: 2,
        px: 2,
      }}
    >
      <Box>
        <img src={Shape} alt="" width="24px" />
      </Box>
      <Box
        sx={{
          pl: 2,
        }}
      >
        <Typography variant="body2" color="#252525">
          {subtitle}
        </Typography>
      </Box>
    </Box>
  );
}

function PackView({
  subtitle1,
  subtitle2,
  subtitle3,
  subtitle4,
  value,
  valueP,
  price,
  packageV,
  onCall,
  t,
  row,
  setSLoader
}) {
  return (
    <Card
      sx={{
        background: "#fff",
        py: 4,
      }}
    >
      <Box>
        <Box
          sx={{
            pb: 5,
            pl: 4,
          }}
        >
          <Typography variant="h3" color="#135050">
            {value}
          </Typography>
        </Box>
        <Box>
          <PacktextView subtitle={subtitle1} />
        </Box>
        <Box>
          <PacktextView subtitle={subtitle2} />
        </Box>
        <Box>
          <PacktextView subtitle={subtitle3} />
        </Box>
        <Box>
          <PacktextView subtitle={subtitle4} />
        </Box>
        <Box
          sx={{
            mt: 5,
            pb: 3,

            textAlign: "center",
          }}
        >
          <Typography variant="body3" color="#489C9C" fontWeight="700">
            ${+row[t] / 100}/-
          </Typography>
        </Box>
        <Box
          sx={{
            px: 4,
          }}
        >
          <Button
            onClick={() => {
              setSLoader();
              onCall(valueP, packageV);
            }}
            color="primary"
            sx={{
              background: "#15423F",
              width: "100%",

              py: 1,
              ":hover": {
                background: "#15423F",
              },
            }}
          >
            <Typography sx={{ color: "white" }}> Subscribe Now</Typography>
          </Button>
        </Box>
      </Box>
    </Card>
  );
}

function GridView({ children }) {
  return (
    <Grid item xs={12} md={12} lg={12}>
      {children}
    </Grid>
  );
}
